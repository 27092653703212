import React, { Component } from 'react';
import classNames from 'classnames';

import Regions from '../Regions/Regions';

export default class RegionsWrapper extends Component {
    classes() {
        return classNames(`wrapper__regions`, {
            "wrapper__regions--highlight": !this.props.currentRegion
        });
    }
    render() {
        return (
            <div className={this.classes()}>
                <Regions {...this.props} />
            </div>
        );
    }
}
