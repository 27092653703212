import React, { Component } from 'react';

export default class Body extends Component {
    render() {
        return (
            <div className="panel__body">
                <div className="panel__body-inner">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
