import Panel from './Panel';
import Header from './Header';
import Title from './Title';
import Badge from './Badge';
import Controls from './Controls';
import Control from './Control';
import Body from './Body';
import Footer from './Footer';

import './style.scss';

export {
    Header as PanelHeader,
    Badge as PanelBadge,
    Title as PanelTitle,
    Controls as PanelControls,
    Control as PanelControl,
    Body as PanelBody,
    Footer as PanelFooter
};

export default Panel;