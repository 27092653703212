import React, { Component } from 'react';

import Chart from '../Chart/Chart';

export default class ChartWrapper extends Component {
    render() {
        return (
            <div className="wrapper__chart">
                <Chart {...this.props} />
            </div>
        );
    }
}
