
const getRegionByCode = (code, regions) => {
    let matches = regions.filter(r => r.code === code);
    if (matches[0] !== void 0) {
        return Object.assign({}, {
            name: null,
        }, matches[0]);
    }
    return null;
};

const getQuestionByKey = (key, questions) => {
    let matches = questions.filter(r => r.key === key);
    if (matches[0] !== void 0) {
        return Object.assign({}, {
            question: null,
            heading: null,
            description: null,
            results: null,
        }, matches[0]);
    }
    return null;
};

const sortDataQuestions = dataQuestions => {
    return dataQuestions.map(question => {
        question.results.values.sort((a,b) => {
            if (a.value < b.value) {
                return -1;
            }
            if (a.value > b.value) {
                return 1;
            }
            return 0;
        })
        return question;
    })
};

export { getRegionByCode, getQuestionByKey, sortDataQuestions };
