import React, { Component } from 'react';

import Panel from '../Panel';
import Stat from '../Stat/Stat';
import QuestionSelector from '../QuestionSelector/QuestionSelector';

import './style.scss';

export default class Results extends Component {
    currentQuestionValueForRegion() {
        const { currentQuestion, currentRegion } = this.props;
        if (!currentQuestion || !currentRegion) {
            return false;
        }
        for (let i = 0; i < currentQuestion.results.values.length; i++) {
            if (currentQuestion.results.values[i].key === currentRegion.code) {
                return currentQuestion.results.values[i].value;
            }
        }
        return null;
    }
    render() {
        const { currentRegion, currentQuestion, questionSelectClickHandler, questions } = this.props;
        return (
            <Panel title="Choose a Question...">
                <div className="results">
                    <div className="results__content">

                        <QuestionSelector questions={questions} questionSelectClickHandler={questionSelectClickHandler} />

                        {currentQuestion && (
                            <div className="results__description">
                                <h3>{currentQuestion.question}</h3>
                                {currentQuestion.description && currentQuestion.description.map((text, i) => (
                                    <p key={i}>{text}</p>
                                ))}
                            </div>
                        )}

                    </div>
                    {currentQuestion && currentRegion && (
                        <div className="results__stats">
                            <Stat label={currentRegion.name} value={this.currentQuestionValueForRegion()} />
                            {currentQuestion && currentQuestion.results.average && (
                                <Stat label="England" value={currentQuestion.results.average} />
                            )}
                        </div>
                    )}
                </div>
            </Panel>
            
        );
    }
}
