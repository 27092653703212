import React, { Component } from 'react';

import './style.scss';
import logo from './logo.svg';

export default class Header extends Component {
    render() {
        return (
            <header className="header" role="banner">
                <img src={logo} alt="The Neurological Alliance" width="220" height="68" className="header__logo" />
                <div className="header__content">
                    <h1>Regional variation in neurology patient experience</h1>
                    <p>The data below is from the National Neurology Patient Experience Survey, and was gathered between October 2018 and March 2019. The interactive map and chart shows the patient experience survey scores for each Sustainability and Transformation Partnership footprint, compared to the average scores for England. For each question, the nearer to 100% the score, the better the experience of people with neurological conditions. The full survey findings, along with the stories behind the data, can be read in <a href="https://www.neural.org.uk/resource_library/neuro-patience/" target="_blank" rel="nofollow noopener">Neuro Patience</a> and the accompanying <a href="https://www.neural.org.uk/resource_library/neuro-patience/" target="_blank" rel="nofollow noopener">technical report</a>.</p>
                </div>
            </header>
        );
    }
}
