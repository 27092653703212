import React, { Component } from 'react';

export default class Controls extends Component {
    render() {
        return (
            <div className="panel__controls">
                {this.props.children}
            </div>
        );
    }
}
