import React, { Component } from 'react';

import Wrapper, { Chart, Results, Regions } from './components/Wrapper';

import dataQuestions from './data/data.json';
import dataRegions from './data/regions.json';

import { getRegionByCode, getQuestionByKey, sortDataQuestions } from './utils';

export default class App extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            currentRegion: `E54000044`,
            currentQuestion: `wait_for_neurologist`
        };
    }
    handleCurrentRegionClick(region) {
        this.setState({ currentRegion: region });
    }
    handleRemoveRegionClick() {
        this.setState({ currentRegion: null });
    }
    handleCurrentQuestionClick(question) {
        this.setState({ currentQuestion: question });
    }
    handleRemoveQuestionClick() {
        this.setState({ currentQuestion: null });
    }
    render() {
        const currentRegion = getRegionByCode(this.state.currentRegion, dataRegions);
        const currentQuestion = getQuestionByKey(this.state.currentQuestion, sortDataQuestions(dataQuestions));

        return (
            <div className="app">

                <Wrapper showChart={this.state.currentQuestion && this.state.currentRegion}>

                    <Regions
                        regions={dataRegions}
                        currentRegion={currentRegion}
                        currentQuestion={currentQuestion}
                        regionSelectClickHandler={this.handleCurrentRegionClick.bind(this)}
                        regionRemoveClickHandler={this.handleRemoveRegionClick.bind(this)}
                    />

                    <Results
                        questions={sortDataQuestions(dataQuestions)}
                        regions={dataRegions}
                        currentRegion={currentRegion}
                        currentQuestion={currentQuestion}
                        questionSelectClickHandler={this.handleCurrentQuestionClick.bind(this)}
                        questionRemoveClickHandler={this.handleRemoveQuestionClick.bind(this)}
                    />

                    {this.state.currentQuestion && this.state.currentRegion && (
                        <Chart
                            questions={sortDataQuestions(dataQuestions)}
                            regions={dataRegions}
                            currentQuestion={this.state.currentQuestion}
                            currentRegion={this.state.currentRegion}
                        />
                    )}

                </Wrapper>

            </div>
        )
    }
}
