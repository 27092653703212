import React, { Component } from 'react';
import { ComposableMap, ZoomableGroup, Geographies, Geography } from 'react-simple-maps';

export default class Map extends Component {
    constructor() {
        super(...arguments);
        this.state = { currentMouseOver: null };
    }
    handleGeographyClick(e) {
        const code = e.properties.code;
        for (let r of this.props.regions) {
            if (r.code === code) {
                this.props.regionSelectClickHandler(r.code);
            }
        }
    }
    render() {
        const { currentRegion } = this.props;
        return (
            <div className="map">
                <ComposableMap
                    style={{ width: `100%`, height: `100%`, display: `block` }}
                    projectionConfig={{
                        scale: 4500
                    }}
                >
                    <ZoomableGroup center={[-2, 52.885]} zoom={1} disablePanning={true}>
                        <Geographies geography={`/map.json`} disableOptimization={true}>
                            {(geographies, projection) => geographies.map((geography, i) => {
                                return (
                                    <Geography
                                        key={`${geography.id}-${i}`}
                                        geography={geography}
                                        projection={projection}
                                        onClick={this.handleGeographyClick.bind(this)}
                                        style={{
                                            default: {
                                                fill: currentRegion && currentRegion.code === geography.properties.code ? `#3A1772` : `#e6e3f2`,
                                                stroke: `#806FAD`,
                                                strokeWidth: 0.5,
                                                outline: `none`,
                                                transition: `fill .25s ease`
                                            },
                                            hover: {
                                                fill: currentRegion && currentRegion.code === geography.properties.code ? `#3A1772` : `#806FAD`,
                                                stroke: `#806FAD`,
                                                strokeWidth: 1,
                                                outline: `none`,
                                                cursor: `pointer`
                                            },
                                            pressed: {
                                                fill: `#3A1772`,
                                                stroke: `#3A1772`,
                                                strokeWidth: 1,
                                                outline: `none`,
                                                cursor: `pointer`
                                            }
                                        }}
                                    />
                                );
                            })}
                        </Geographies>
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        );
    }
}
