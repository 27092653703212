import React, { Component } from 'react';
import classNames from 'classnames';

import Results from '../Results/Results';

export default class ResultsWrapper extends Component {
    classes() {
        return classNames(`wrapper__results`, {
            "wrapper__results--highlight": this.props.currentRegion && !this.props.currentQuestion,
            "wrapper__results--dim": !this.props.currentRegion
        });
    }
    render() {
        return (
            <div className={this.classes()}>
                <Results {...this.props} />
            </div>
        );
    }
}


