import React, { Component } from 'react';

import Panel, { PanelHeader, PanelBody, PanelFooter, PanelControl, PanelControls, PanelTitle, PanelBadge } from '../Panel';
import List from './List';
import Map from './Map';

export default class Regions extends Component {
    constructor() {
        super(...arguments);
        this.state = { isMap: true };
    }
    handleListViewClick() {
        this.setState({ isMap: false });
    }
    handleMapViewClick() {
        this.setState({ isMap: true });
    }
    componentDidMount() {
        if (window.innerWidth && window.innerWidth < 992) {
            this.setState({ isMap: false });
        }
    }
    render() {
        const { currentRegion, regions, regionSelectClickHandler, regionRemoveClickHandler } = this.props;
        return (
            <Panel>
                <PanelHeader>
                    <PanelTitle>
                        STP's
                        {currentRegion && (
                            <PanelBadge deleteClickHandler={regionRemoveClickHandler}>{currentRegion.name}</PanelBadge>
                        )}
                    </PanelTitle>
                    <PanelControls>
                        <PanelControl onClick={this.handleListViewClick.bind(this)} isActive={!this.state.isMap}>
                            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M8 128h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm496 112H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zm-64 144H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h432c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8z"></path></svg>
                            List
                        </PanelControl>
                        <PanelControl onClick={this.handleMapViewClick.bind(this)} isActive={this.state.isMap}>
                            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"></path></svg>
                            Map
                        </PanelControl>
                    </PanelControls>
                </PanelHeader>
                <PanelBody>
                    {this.state.isMap ? (
                        <Map
                            regions={regions}
                            currentRegion={currentRegion}
                            regionSelectClickHandler={regionSelectClickHandler}
                        />
                    ) : (
                        <List
                            regions={regions}
                            currentRegion={currentRegion}
                            regionSelectClickHandler={regionSelectClickHandler}
                        />
                    )}
                </PanelBody>
                <PanelFooter>
                    National Statistics data &amp; OS data &copy; Crown 2018
                </PanelFooter>
            </Panel>
        );
    }
}
