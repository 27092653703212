import React, { Component } from 'react';

import './style.scss';

export default class Stat extends Component {
    value() {
        if (`${this.props.value}`.includes(`%`)) {
            const v = parseFloat(this.props.value);
            return <>{Math.round(v)}<span>%</span></>;
        }
        return this.props.value;
    }
    render() {
        return (
            <div className="stat">
                <p className="stat__label">{this.props.label}</p>
                <p className="stat__value">{this.value()}</p>
            </div>
        );
    }
}
