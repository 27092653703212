import React, { Component } from 'react';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';

export default class Panel extends Component {
    render() {
        const { title, footerText } = this.props;
        return (
            <section className="panel">

                {title && (
                    <Header title={title} />
                )}

                {title || footerText ? (
                    <Body>{this.props.children}</Body>
                ) : (
                    this.props.children
                )}
                
                {footerText && (
                    <Footer>{footerText}</Footer>
                )}

            </section>
        );
    }
}
