import React, { Component } from 'react';
import classNames from 'classnames';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import './style.scss';

export default class Wrapper extends Component {
    classes() {
        return classNames(`wrapper`, {
            "wrapper--has-chart": this.props.showChart
        });
    }
    render() {
        return (
            <>

                <Header />

                <div className={this.classes()}>
                    {this.props.children}
                </div>

                <Footer />

            </>
        );
    }
}
