import React, { Component } from 'react';

import './style.scss';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer" role="contentinfo">
                <div className="footer__inner">
                    <p>Copyright © Neurological Alliance, {new Date().getFullYear()}.</p>
                    <p>Neurological Alliance Company Limited by Guarantee Registered in England 2939840 Registered charity 1039034. All rights reserved. London, UK</p>
                </div>
            </footer>
        );
    }
}
