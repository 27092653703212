import React, { Component } from 'react';
import Select from 'react-select';

import './style.scss';

export default class QuestionSelector extends Component {
    constructor() {
        super(...arguments);
        this.selectChangeHandler = this.selectChangeHandler.bind(this);
    }
    selectChangeHandler(e) {
        for (let q of this.props.questions) {
            if (q.key === e.value) {
                this.props.questionSelectClickHandler(q.key);
            }
        }
    }
    render() {
        const options = this.props.questions.map(q => ({ value: q.key, label: q.heading }));
        return (
            <div className="question-selector">
                <Select
                    options={options}
                    onChange={this.selectChangeHandler}
                    placeholder="Select question..."
                    maxMenuHeight={180}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: `#3A1772`,
                            primary75: `#806FAD`,
                            primary50: `#cec8e2`,
                            primary25: `#e6e3f2`,
                        }
                    })}
                />
            </div>
        );
    }
}
