import React, { Component } from 'react';

import Title from './Title';

export default class Header extends Component {
    render() {
        return (
            <div className="panel__header">
                {this.props.title && <Title>{this.props.title}</Title>}
                {this.props.children}
            </div>
        );
    }
}
