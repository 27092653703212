import React, { Component } from 'react';
import Chart from 'chart.js';

import Panel from '../Panel';

import { getQuestionByKey, getRegionByCode } from '../../utils';

export default class MyChart extends Component {
    constructor() {
        super(...arguments);
        this.chart = null;
    }
    calculateChartLabels(question, questions, regions) {
        const data = questions.filter(q => q.key === question.key)[0];
        if (data) {
            return data.results.values
                .map(q => q ? getRegionByCode(q.key, regions) : null)
                .map(q => q.name)
                .filter(a => a);
        }
        return [];
    }
    calculateChartValues(question, questions) {
        const data = questions.filter(q => q.key === question.key)[0];
        if (data) {
            return data.results.values
                .map(q => q ? parseFloat(q.value) : null)
                .filter(a => a)
        }
        return [];
    }
    calculateChartBarColours(question, region, questions) {
        // 
        const colors = [];
        for (let i = 0; i < question.results.values.length; i++) {
            const color = question.results.values[i].key === region ? `#3A1772` : `#cec8e2`;
            colors.push(color);
        }
        return colors;
    }
    calculateChartAverageValues(question) {
        const data = [];
        for (let i = 0; i < question.results.values.length; i++) {
            data.push(parseFloat(question.results.average));
        }
        return data;
    }
    drawChart() {
        const { currentRegion, currentQuestion, questions, regions } = this.props;
        const question = getQuestionByKey(currentQuestion, questions);
        
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = new Chart(document.getElementById(`canvas`), {
            type: `bar`,
            data: {
                labels: this.calculateChartLabels(question, questions, regions),
                datasets: [
                    {
                        type: `line`,
                        data: this.calculateChartAverageValues(question),
                        label: 'Line Dataset',
                        fill: false,
                        borderColor: `rgba(255, 0, 0, .75)`,
                        borderWidth: 1,
                        lineTension: 0,
                        borderDash: [4, 6],
                        pointRadius: 0,
                    },
                    {
                        label: `Percentage`,
                        data: this.calculateChartValues(question, questions),
                        backgroundColor: this.calculateChartBarColours(question, currentRegion, questions),
                    }
                ]
            },
            options: {
                scales: {
                    yAxes: [{ ticks: { beginAtZero: true } }],
                    xAxes : [{ display: false, gridLines : { display : false } }]
                },
                legend: {
                    display: false
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                tooltips: {
                    callbacks: {
                        label: (item, data) => {
                            const dataset = data['datasets'][1];
                            return `${dataset['data'][item['index']]}${question.results.type !== `percentage` ? `` : `%`}`;
                        }
                    },
                    backgroundColor: '#FFFFFF',
                    titleFontSize: 14,
                    titleFontColor: '#3A1772',
                    bodyFontColor: '#4A5568',
                    bodyFontSize: 20,
                    displayColors: false,
                    yPadding: 12,
                    xPadding: 12,
                    borderColor: `#806FAD`,
                    borderWidth: 1,
                    cornerRadius: 3
                }
            },
        });
    }

    componentDidMount() {
        this.drawChart();
    }
    componentDidUpdate() {
        this.drawChart();
    }
    render() {
        return (
            <Panel title="Results for each STP in England">
                <canvas id="canvas" style={{ width: `100%`, height: `100%` }}></canvas>
            </Panel>
        );
    }
}
