import React, { Component } from 'react';
import classNames from 'classnames';

export default class Control extends Component {
    classes() {
        return classNames(`panel__control`, {
            "panel__control--active": this.props.isActive
        });
    }
    render() {
        const { isActive, ...props } = this.props;
        return (
            <button {...props} className={this.classes()} />
        );
    }
}
