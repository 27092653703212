import React, { Component } from 'react';
import classNames from 'classnames';

import './style.scss';

export default class List extends Component {
    classes(code) {
        return classNames(`region-list__button`, {
            "region-list__button--active": this.props.currentRegion && this.props.currentRegion.code === code
        });
    }
    render() {
        const { regions, regionSelectClickHandler } = this.props;
        return (
            <div className="region-list">
                {regions.map(region => (
                    <button className={this.classes(region.code)} key={region.code} onClick={() => regionSelectClickHandler(region.code)}>
                        {region.name}
                    </button>
                ))}
            </div>
        );
    }
}
